export default class Gallery extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-gallery';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost, componentName) {
    // Call the constructor of the parent class (BaseComponent)
    super(componentHost, componentName);
  }
  /** @inheritDoc */
  // Initialize function
  initialize() {
    this.navLinks = this.componentHost?.querySelectorAll('.ace-gallery-nav .nav-link');
    this.gallerySection = this.componentHost?.querySelectorAll('.ace-gallery-section .aem-gallery-section');
    this.startScrollObserver();
  }

  /**
   * Adding intersection observer, if section on view port active the nav link panel accordingly.
   */
  startScrollObserver() {
    const thresholdDefinition = {
      rootMargin: '-116px 0px -67% 0px',
      threshold: 0
    };
    this.galleryObserver = new IntersectionObserver((items) => {
      items.forEach((entry) => {
        entry.target.classList.remove('current');
        if (entry.isIntersecting) {
          this.navLinks?.forEach((item) => {
            if (`#${entry?.target?.getAttribute('id')}` === decodeURI(item?.getAttribute('href'))) {
              this.navLinks?.forEach((linkItem) => {
                linkItem.classList?.remove('active');
                linkItem.removeAttribute('aria-current');
              });
              entry.target.classList.add('current');
              item.classList.add('active');
              item.setAttribute('aria-current', 'page');
              item.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
            }
          });
        }
      });
    }, thresholdDefinition);
    this.gallerySection?.forEach(( section ) => {
      this.galleryObserver?.observe(section);
    });
  }
}
// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(Gallery.CLASS_NAMESPACE, Gallery);
