export default class MeetingCapacityChart extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'sofitel-meeting-capacity-chart';

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost, componentName) {
    // Call the constructor of the parent class (BaseComponent)
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  // Initialize function
  initialize() {
    const accordionButtons = document.querySelectorAll('.js-meeting-capacity-chart-tableItemButton');

    function toggleAccordion(e) {
      const accordionButton = e.currentTarget;
      const accordionContent = accordionButton.nextElementSibling;

      // Handle accordion button behavior
      accordionButton.classList.toggle('meeting-capacity-chart-tableItemButton--open');
      accordionButton.setAttribute(
        'aria-expanded',
        accordionButton.getAttribute('aria-expanded') === 'true' ?
          'false' :
          'true'
      );

      // Handle accordion content behavior
      accordionContent.classList.toggle('meeting-capacity-chart-tableItemList--open');
      accordionContent.setAttribute(
        'aria-hidden',
        accordionContent.getAttribute('aria-hidden') === 'true' ?
          'false' :
          'true'
      );
    }

    accordionButtons.forEach((item) => item.addEventListener('click', toggleAccordion));
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(MeetingCapacityChart.CLASS_NAMESPACE, MeetingCapacityChart);
