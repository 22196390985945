/**
 *  *
 * @author Dharsini
 * @class Welcome
 * @classdesc Ace Welcome component to inherit the base component
 * @inheritDoc
 */

import { WeclomeMessage } from '@accor/ace-ui-core';

import { TagManager } from '@accor/ace-ui-core';
export default class SofitelWeclomeMessage extends WeclomeMessage {
  static CLASS_NAMESPACE = 'ace-welcome-component';
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  // Initialize function
  initialize() {
    super.initialize();

    // Call Animation on the welcome
    this.welcomeAnimation();

    this.bindEventHandler();
  }

  /**
  * Invoking Animation on welcome when 30 percent of the component is on view
  */
  welcomeAnimation() {
    const thresholdDefinition = {
      threshold: 0.3
    };
    this.welcomeObserver = new IntersectionObserver((item) => {
      item.forEach((entry) => {
        if (entry.isIntersecting) {
          this.componentHost?.classList.add('set-animation');
          this.welcomeObserver?.unobserve(this.componentHost);
        }
      });
    }, thresholdDefinition);
    this.welcomeObserver?.observe(this.componentHost);
  }

  /**
  * binding event handlers on welcome ctas
  */
  bindEventHandler() {
    const welcomeLocation = this.componentHost.querySelector('.ace-welcome-component__location');
    const welcomeCTA = this.componentHost.querySelector('.ace-welcome-component__cta');
    const locationMap = 'adress';
    const locationPhone = 'phone';
    const ctaBroucher = 'meeting brochure';
    welcomeLocation?.querySelector('.ace-welcome-component__location__map a')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.handleTagManager(locationMap);
    });
    welcomeLocation?.querySelector('.ace-welcome-component__location__phone a')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.handleTagManager(locationPhone);
    });
    welcomeCTA?.querySelector('.cmp-button')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.handleTagManager(ctaBroucher);
    });
  }

  /**
  * @param {string} data
  * Push data in tagmanager
  */
  handleTagManager(data) {
    const dataLayerValue = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
      const dataEvent = {
        event: 'GA4event',
        eventName: 'bloc_interact',
        event_data: {
          pagename: this.dataLayerJson?.pageName,
          bloc_name: data
        }
      };
      TagManager.trackEvent(dataEvent);
    }
  }
}

// Register the Welcome component with the BaseComponent
CoreJS.BaseComponent.registerComponent(WeclomeMessage.CLASS_NAMESPACE, SofitelWeclomeMessage, true);
