/**
 * @author Jonas.Fournel
 * @class SofitelBookingEngine
 * @classdesc JS Class handling Sticky Booking Engine component with HTML tag 'sticky-booking-engine'
 */
import { TagManager } from '@accor/ace-ui-core';
export default class StickyBookingEngine extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'sticky-booking-engine';

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  initialize() {
    this.mainWrapper = this.componentHost.querySelector('.sticky-booking-engine-wrapper');
    this.focusWrapper = this.componentHost.querySelector('.booking');
    this.stickyOverlay = this.mainWrapper.querySelector('.searching');
    this.datePickerOverlay = this.mainWrapper.querySelector('.booking');
    this.closeIcon = this.mainWrapper.querySelector('.close-icon');
    this.bookingEngineOpenButton = this.datePickerOverlay.querySelector('.ace-core-booking-engine__open--button');
    this.bookingEngineCloseButton = this.datePickerOverlay.querySelector('.ace-core-booking-engine__close--button');
    if (this.mainWrapper.getAttribute('data-booking-open') !== 'true') {
      this.mainWrapper.parentElement.classList.add('collapsed');
    }
    const focusable = this.focusWrapper.querySelectorAll('input, button');
    let visibleArray = [];

    focusable.forEach((element) => {
      const elementStyle = window.getComputedStyle(element);
      const isDisplayNone = elementStyle.display === 'none';
      const isHidden = elementStyle.visibility === 'hidden';

      if ( !isDisplayNone && !isHidden) {
        visibleArray = [...visibleArray, element];
      }
    });

    this.stickyOverlay.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      setTimeout(()=> {
        focusable[0].focus();
      }, 10);
    });

    this.focusWrapper.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (e) => {
      //manage the navigation by tab and shift tab
      if (e.which === 9) {
        const firstFocusable = visibleArray[0];
        const lastFocusable = visibleArray[visibleArray.length - 1];
        const shift = e.shiftKey;

        if (shift) {
          if (document.activeElement === firstFocusable) {
            lastFocusable.focus();
            e.preventDefault();
          }
        } else {
          if ( document.activeElement === lastFocusable) {
            firstFocusable.focus();
            e.preventDefault();
          }
        }
      }
      //manage the clocsing of booking engine by pressing escape
      if (e.which === 27) {
        //if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
           this.closingBookingEngine();
          //}
      }

      
    });

    this.stickyOverlay.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
      event.preventDefault();
      this.mainWrapper.classList.add('opened');
      this.datePickerOverlay.classList.add('active');
      this.bookingEngineOpenButton.dispatchEvent(new Event('click'));
      
      this.tagManagerHandler(event);
    });
    this.closeIcon.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
       // if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
          this.closingBookingEngine();
       // }
    });

    this.bookingEngineCloseButton.addEventListener(CoreJS.DomEventConstants.CLICK, ()=>{
     // if (window.innerWidth <= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        this.datePickerOverlay.classList.remove('active');
        this.mainWrapper.classList.remove('opened');
     // }
    });

    
    // scroll event
    this.checkIfViewPortIsScroll();
    document.addEventListener('scroll', ()=> {
      this.checkIfViewPortIsScroll();
    });
  }

   checkIfViewPortIsScroll () {
    if (window.innerHeight < window.scrollY) {
      this.mainWrapper.parentElement.classList.add('visible');
      this.mainWrapper.parentElement.style.minHeight = null;
    } else {
      this.mainWrapper.parentElement.classList.remove('visible');
      this.checkIfHotelExperiencePage();
    }
   }

   checkIfHotelExperiencePage () {
    // if hotel-experience-detail-page
    if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg && document.body.classList.contains('hotel-experience-detail-page') && document.querySelector('.heading-offer')) {
      this.mainWrapper.parentElement.style.minHeight = `${document.querySelector('.heading-offer').offsetHeight}px`;
    }
   }
  closingBookingEngine() {
    this.stickyOverlay.classList.remove('hidden');
    this.datePickerOverlay.classList.remove('active');
    this.mainWrapper.classList.remove('opened');
    if (this.mainWrapper.getAttribute('data-booking-open') !== 'true') {
      this.mainWrapper.parentElement.classList.add('collapsed');
    }
    this.stickyOverlay.focus();

    this.componentHost.setAttribute('aria-modal', false);
    document.documentElement.classList.remove('fixedbyModal');
  }

  /**
     * Push data in tagmanager
     * @param {object} event
     */
  tagManagerHandler(event) {
    const dataLayerValue = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
      const dataEvent = {
        event: 'GA4event',
        eventName: 'booking_form_interact',
        event_data: {
          pagename: this.dataLayerJson?.pageName,
          form_action: 'open booking engine',
          bloc_name: event.target?.innerText?.trim()?.toLowerCase()
        }
      };
      TagManager.trackEvent(dataEvent);
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(StickyBookingEngine.CLASS_NAMESPACE, StickyBookingEngine);
