/* eslint-disable no-unused-expressions */
/**
 * Class representing a Sofitel Social Media Component instance
 * @author Rishabh
 * @class SofitelSocialMedia
 * @classdesc Sofitel Social Media component to inherit the Social Media core component
 * @extends SocialMedia
 * @version 1.0
 */
import { SocialMedia, TagManager } from '@accor/ace-ui-core';

export default class SofitelSocialMedia extends SocialMedia {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
    const dataLayerValue = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
    }

    this.socialMediaFollowLink = this.componentHost.querySelector('.cmp-socialMedia_cta a');
    this.TagManagerHandler();
  }

  /**
     * Push data in tagmanager
     */
  TagManagerHandler() {
    if (this.dataLayerJson) {
      this.socialMediaFollowLink?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        const dataEvent = {
          event: 'GA4event',
          eventName: 'bloc_interact',
          event_data: {
            pagename: this.dataLayerJson?.pageName,
            bloc_name: 'get social',
            bloc_interaction: 'follow us'
          }
        };
        TagManager.trackEvent(dataEvent);
      });
    }
  }
}


// Register the Sofitel Social Media component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(SocialMedia.CLASS_NAMESPACE, SofitelSocialMedia, true);
