// Stylesheets
import './theme.scss';
import './resources/images/favicon.svg';

// ACE Library JS
import "@accor/ace-ui-core";

// COMMON JS Components
import './components/common/core-booking-engine/v1/js/core-booking-engine';
import './components/common/meeting-capacity-chart/v1/meeting-capacity-chart/js/meeting-capacity-chart.js';
import './components/common/welcome-message/v1/js/welcome-message.js';
import './components/common/immersive-heading/v1/immersive-heading/js/immersive-heading';
import './components/common/special-touches/v1/special-touches/js/special-touches';
import './components/common/sticky-booking-engine/v1/sticky-booking-engine/js/sticky-booking-engine';
import './components/common/room-amenities/v1/js/room-amenities.js';
import './components/common/back-button/v1/js/back-button';
import './components/common/quick-access/v2/js/quick-access-v2';
import './components/common/callouts/v1/callout-container/js/callout-container';
import './components/common/product-booking/v1/product-booking/js/product-booking';
import './components/common/social-media/v1/js/social-media';
import './components/common/sticky-button-search/v1/js/sticky-button-search';
import './components/common/callouts/v1/callout/js/callout';
import './components/common/core-booking-engine/v1/destination-dropdown/js/destination-dropdown';

// CONTENT JS Components
import './components/content/gallery/v1/js/gallery.js';
import "./components/content/modal/v1/js/modal.js";
import "./components/content/card-editorial-slideshow/v1/js/card-editorial-slideshow";
import "./components/content/slideshow-carousel/v1/js/slideshow-carousel.js";

// STRUCTURE JS Components
import "./components/structure/base-page-template/v1/js/base-page-template";

document.dispatchEvent(new Event(CoreJS.CustomDomEventConstants.THEME_LOADED_EVENT));
