/* eslint-disable no-unused-expressions */
/**
 * Class representing a Ace Callout Container Component instance
 * @author Rishabh
 * @class SofitelCalloutContainer
 * @classdesc Ace callout container component to inherit the callout container core component
 * @extends CalloutContainer
 * @version 1.0
 */
import { CalloutContainer, TagManager } from '@accor/ace-ui-core';

export default class SofitelCalloutContainer extends CalloutContainer {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
    const dataLayerValue = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
    }

    // check if filter is applicable for callout and call tagmanager if yes
    this.filterCalloutContainerItem = this.componentHost.querySelector('.ace-callout-container-filters__tab-nav');
    if (this.filterCalloutContainerItem) {
      this.callFilterTagManagerHandle();
    }

    this.carouselCalloutContainerItem = this.componentHost.querySelectorAll('.section.callout-section ul li');
    this.callCarouselTagManagerHandle();
    this.callCalloutSeeMore();
    this.callCalloutShowMap();
    this.callCalloutBookingEngine();
  }

  /**
     * push ga events for filter tabs in tag manager
     */
  callFilterTagManagerHandle() {
    if (this.dataLayerJson) {
      let blockName = '';
      if (this.dataLayerJson?.pageName == 'sofitel::hotels::homepage') { // Hotel homepage
        blockName = this.componentHost.parentElement.previousElementSibling.querySelector('div')?.getAttribute('id');
      } else if (this.dataLayerJson?.pageName == 'sofitel::hotels::room detail page') { // room detail page
        blockName = 'suites and rooms';
      }
      this.filterCalloutContainerItem.querySelectorAll('.ace-callout-container-filters__tab-list li').forEach((element, index) => {
        element?.querySelector('.ace-callout-container-filters__tab-button')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const dataEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: this.dataLayerJson?.pageName,
              bloc_name: blockName,
              menu_position: index + 1,
              menu_label: element?.innerText?.trim()?.toLowerCase()
            }
          };
          TagManager.trackEvent(dataEvent);
        });
      });
    }
  }


  /**
     * push ga events for callouts in tag manager depending on the page
     */
  callCarouselTagManagerHandle() {
    let blockName = this.componentHost?.parentElement?.previousElementSibling?.querySelector('div')?.getAttribute('id');
    let interactionType = 'details';
    let interaction = '';
    if (this.dataLayerJson) {
      this.carouselCalloutContainerItem?.forEach((element) => {
        if ((this.dataLayerJson?.pageName === 'sofitel::hotels::homepage' && (blockName === 'accomodation' || blockName === 'dining')) ||
        this.dataLayerJson?.pageName === 'sofitel::hotels::restaurant detail page' ||
        this.dataLayerJson?.pageName === 'sofitel::hotels::room detail page') { // hotel homepage accomodation and dining callouts OR Restraunt detail page OR Room detail page
          element?.querySelector('.ace-callout-component .ace-button .cmp-button')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            interaction = element.querySelector('.ace-callout-component')?.getAttribute('data-product-id');
            if (blockName === 'accomodation') {
              interactionType = '- check availability';
            }
            if (this.dataLayerJson?.pageName === 'sofitel::hotels::restaurant detail page') { // Restraunt detail page
              blockName = 'caroussel explore more';
              interaction = 'view';
            }
            if (this.dataLayerJson?.pageName === 'sofitel::hotels::room detail page') { // Room detail page
              blockName = element?.querySelector('.ace-callout-component .cmp-teaser__content .cmp-teaser__pretitle')?.innerText?.trim()?.toLowerCase();
            }
            const dataEvent = {
              event: 'GA4event',
              eventName: 'bloc_interact',
              event_data: {
                pagename: this.dataLayerJson?.pageName,
                bloc_name: blockName,
                bloc_interaction: `${interaction} ${interactionType}`
              }
            };
            if (this.dataLayerJson?.pageName === 'sofitel::hotels::room detail page') { // Room detail page
              delete dataEvent?.event_data.bloc_interaction;
            }
            TagManager.trackEvent(dataEvent);
          });
          element?.querySelector('.ace-callout-component .ace-button .cmp-teaser__link')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            interaction = element.querySelector('.ace-callout-component')?.getAttribute('data-product-id');
            if (this.dataLayerJson?.pageName === 'sofitel::hotels::restaurant detail page') { // Restraunt detail page
              blockName = 'caroussel explore more';
              interaction = 'view';
            }
            if (this.dataLayerJson?.pageName === 'sofitel::hotels::room detail page') { // Room detail page
              interaction = '';
              interactionType = '';
              blockName = element?.querySelector('.ace-callout-component .cmp-teaser__content .cmp-teaser__pretitle')?.innerText?.trim()?.toLowerCase();
            }
            const dataEvent = {
              event: 'GA4event',
              eventName: 'bloc_interact',
              event_data: {
                pagename: this.dataLayerJson?.pageName,
                bloc_name: blockName,
                bloc_interaction: `${interaction} ${interactionType}`
              }
            };
            TagManager.trackEvent(dataEvent);
          });
        } else {
          element?.querySelector('.ace-callout-component')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            if (this.dataLayerJson?.pageName === 'sofitel::hotels::homepage' && !(blockName === 'accomodation' || blockName === 'dining')) {
              interaction = element.querySelector('.ace-callout-component .cmp-teaser__pretitle')?.innerText?.trim()?.toLowerCase();
            } else if (this.dataLayerJson?.pageName === 'sofitel::brand::homepage') {
              interaction = this.fetchHotelId(element?.querySelector('.ace-callout-component .cmp-teaser__content .cmp-teaser__pretitle a')?.getAttribute('href'));
              blockName = this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-section-title__content--title')?.innerText?.trim()?.toLowerCase() ?
                this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-section-title__content--title')?.innerText?.trim()?.toLowerCase() :
                this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-shorttitle__heading')?.innerText?.trim()?.toLowerCase();
            } else if (this.dataLayerJson?.pageName === 'sofitel::brand::gastronomy') {
              interaction = `${this.fetchHotelId(element?.querySelector('.ace-callout-component .cmp-teaser__content .cmp-teaser__pretitle a')?.getAttribute('href'))
              } ${this.fetchRestrauntId(element?.querySelector('.ace-callout-component .cmp-teaser__content .cmp-teaser__pretitle a')?.getAttribute('href'))}`;
              blockName = `${this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-section-title__content--title')?.innerText?.trim()?.toLowerCase() ?
                this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-section-title__content--title')?.innerText?.trim()?.toLowerCase() :
                this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-shorttitle__heading')?.innerText?.trim()?.toLowerCase()}`;
            } else if (this.dataLayerJson?.pageName === 'sofitel::brand::wellness') {
              interaction = `${this.fetchHotelId(element?.querySelector('.ace-callout-component .cmp-teaser__content .cmp-teaser__pretitle a')?.getAttribute('href'))} spa`;
              blockName = this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-section-title__content--title')?.innerText?.trim()?.toLowerCase() ?
                this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-section-title__content--title')?.innerText?.trim()?.toLowerCase() :
                this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-shorttitle__heading')?.innerText?.trim()?.toLowerCase();
            } else if (this.dataLayerJson?.pageName === 'sofitel::brand::meetings') {
              interaction = `${this.fetchHotelId(element?.querySelector('.ace-callout-component .cmp-teaser__content .cmp-teaser__pretitle a')?.getAttribute('href'))}`;
              blockName = this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-section-title__content--title')?.innerText?.trim()?.toLowerCase() ?
                this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-section-title__content--title')?.innerText?.trim()?.toLowerCase() :
                this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-shorttitle__heading')?.innerText?.trim()?.toLowerCase();
            } else if (this.dataLayerJson?.pageName === 'sofitel::brand::weddings') {
              interaction = `${this.fetchHotelId(element?.querySelector('.ace-callout-component .cmp-teaser__content .cmp-teaser__pretitle a')?.getAttribute('href'))}`;
              blockName = this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-section-title__content--title')?.innerText?.trim()?.toLowerCase() ?
                this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-section-title__content--title')?.innerText?.trim()?.toLowerCase() :
                this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-shorttitle__heading')?.innerText?.trim()?.toLowerCase();
            } else if (this.dataLayerJson?.pageName === 'sofitel::hotels::city') {
              interaction = element.querySelector('.ace-callout-component .cmp-teaser__pretitle')?.innerText?.trim()?.toLowerCase();
              blockName = this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-section-title__content--title')?.innerText?.trim()?.toLowerCase() ?
                this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-section-title__content--title')?.innerText?.trim()?.toLowerCase() :
                this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-shorttitle__heading')?.innerText?.trim()?.toLowerCase();
            } else if (this.dataLayerJson?.pageName === 'sofitel::hotels::hub') {
              interaction = element.querySelector('.ace-callout-component .cmp-teaser__pretitle')?.innerText?.trim()?.toLowerCase();
              blockName = this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-section-title__content--title')?.innerText?.trim()?.toLowerCase() ?
                this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-section-title__content--title')?.innerText?.trim()?.toLowerCase() :
                this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-shorttitle__heading')?.innerText?.trim()?.toLowerCase();
            }
            const dataEvent = {
              event: 'GA4event',
              eventName: 'bloc_interact',
              event_data: {
                pagename: this.dataLayerJson?.pageName,
                bloc_name: blockName,
                bloc_interaction: interaction
              }
            };
            TagManager.trackEvent(dataEvent);
          });
        }
      });
    }
  }

  /**
     * fetch hotelId from the callout redirect url
     * @param {String} url - Target url string to fetch hotel id from
     * @return {String} hotel id returned
     */
  fetchHotelId(url) {
    const urlList = url.split('/');
    let hotelId = '';
    urlList?.forEach((item, index) => {
      if (item === 'hotels') {
        hotelId = urlList[index + 1]?.replace('.html', '');
      }
    });
    return hotelId;
  }

  /**
     * fetch restraunt from the callout redirect url
     * @param {String} url - Target url string to fetch restraunt id from
     * @return {String} restraunt id returned
     */
  fetchRestrauntId(url) {
    const urlList = url.split('/');
    const restrauntId = urlList[urlList?.length - 1]?.replace('.html', '');
    return restrauntId;
  }

  /**
     * push ga events for callout seemore and explore buttons in tag manager
     */
  callCalloutSeeMore() {
    if (this.dataLayerJson) {
      if (this.componentHost?.querySelector('.ace-loyalty__inner.ace-button')) { // For see more and explore more CTAs
        this.componentHost?.querySelector('.ace-loyalty__inner.ace-button .ace-guest-viewmore')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const dataEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: this.dataLayerJson?.pageName,
              bloc_name: this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-section-title__content--title')?.innerText?.trim()?.toLowerCase() ?
                this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-section-title__content--title')?.innerText?.trim()?.toLowerCase() :
                this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-shorttitle__heading')?.innerText?.trim()?.toLowerCase(),
              bloc_interaction: 'see more'
            }
          };
          TagManager.trackEvent(dataEvent);
        });
        this.componentHost?.querySelector('.ace-loyalty__inner.ace-button a:not(.ace-guest-viewmore')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const dataEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: this.dataLayerJson?.pageName,
              bloc_name: 'explore our cities',
              bloc_interaction: 'explore'
            }
          };
          TagManager.trackEvent(dataEvent);
        });
      }
    }
  }

  /**
     * push ga events for show map button on callout in tag manager
     */
  callCalloutShowMap() {
    if (this.dataLayerJson) {
      if (this.componentHost?.querySelector('.ace-modal-component')) { // For show map on callouts
        this.componentHost?.querySelector('.ace-showmap__button')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const dataEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: this.dataLayerJson?.pageName,
              bloc_name: this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-section-title__content--title')?.innerText?.trim()?.toLowerCase() ?
                this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-section-title__content--title')?.innerText?.trim()?.toLowerCase() :
                this.componentHost?.parentElement?.previousElementSibling?.querySelector('.ace-shorttitle__heading')?.innerText?.trim()?.toLowerCase()
            }
          };
          TagManager.trackEvent(dataEvent);
        });
      }
    }
  }

  updateFormBookingeEngineValues (id = null, value = '') {
    if (!id || value == '') {
      return
    }

    if (document.querySelector('.ace-core-booking-engine input[name="search.destination"]')) {
      document.querySelector('.ace-core-booking-engine input[name="search.destination"]').value = id;
    }
    if (document.querySelector('.ace-core-booking-engine #search-destination input')) {
      document.querySelector('.ace-core-booking-engine #search-destination input').value = value;
    }

    // scroll to form

  }

  getDestinationId(url = null) {
    if (!url) {
      return
    }

    const parameters = new URLSearchParams(url);

    if (parameters.get('destination')) {
      return parameters.get('destination');
    }
  }

  callCalloutBookingEngine () {
    if (document.querySelectorAll('.ace-core-booking-engine').length) {
      this.componentHost?.querySelectorAll('.ace-callout-booking-engine-prefilled').forEach((element) => {
        element.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
          // get text + id
          event.preventDefault();
          this.updateFormBookingeEngineValues(this.getDestinationId(event.target.getAttribute('href')), event.target.innerText || '')
          // update booking engine form
          if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
            document.querySelector('.immersive-heading, .sticky-booking-engine, .booking-engine-container').scrollIntoView({  behavior: "smooth", block: 'end' });
          } else {
            if (document.querySelector('.sticky-booking-engine')) {
              document.querySelector('.sticky-booking-engine .searching').click()
            } else {
              document.querySelector('.booking-engine-container').scrollIntoView({  behavior: "smooth", block: 'end' });
            }
          }
          document.querySelector(".ace-core-booking-engine [data-date='checkin']").show();
        });
      })
    }
  }

}



// Register the callout container component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(CalloutContainer.CLASS_NAMESPACE, SofitelCalloutContainer, true);