import { Modal, TagManager } from '@accor/ace-ui-core';

export default class SofitelModal extends Modal {
  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  openPopIn() {
    const popShow = this.modal;
    // Set the display property of 'popShow' element to 'block'
    popShow.style.display = 'block';
    popShow.setAttribute('aria-modal', 'true');
    document.body.classList.add('disable-scroll-modal');
    this.closeButton?.focus();
    window.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (e) => {
      if (e.which === 9) {
        const focusable = popShow.querySelectorAll('input,button,select,textarea,a,div.ace-gallery-section__images');
        if (focusable.length) {
          const first = focusable[0];
          const last = focusable[focusable.length - 1];
          const shift = e.shiftKey;
          if (shift) {
            if (e.target === first) { // shift-tab pressed on first input in dialog
              last.focus();
              e.preventDefault();
            }
          } else {
            if (e.target === last) { // tab pressed on last input in dialog
              first.focus();
              e.preventDefault();
            }
          }
        }
      }
    });
    document.addEventListener(CoreJS.DomEventConstants.CLICK, (clickEvent) => this.closeModalWindowClick(clickEvent));
    if (this.componentHost.parentElement?.classList?.contains('gallery')) {
      this.handleTagManager();
    }
  }

  /*
   * Close modal when click outside modal container
   */
  closeModalWindowClick(event) {
    if (!event.target.closest('.ace-modal__content') && this.modal?.getAttribute('aria-modal') == 'true' &&
     (!event.target.closest('.ace-highlight__content--link') && !event.target.closest('.ace-gallery-open') && !event.target.closest('.ace-showmap__button') &&
     !event.target.closest('.hotel-block__hotel-close'))) {
      this.closePopIn();
    }
  }

  /*
   * Push data in tagmanager
   */
  handleTagManager() {
    const dataLayerValue = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
      const dataEvent = {
        event: 'GA4event',
        eventName: 'bloc_interact',
        event_data: {
          pagename: this.dataLayerJson?.pageName,
          bloc_name: 'view all photos'
        }
      };
      TagManager.trackEvent(dataEvent);
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(Modal.CLASS_NAMESPACE, SofitelModal, true);
